<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검토요청일 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="1D"
            label="검토요청일"
            name="reviewRequestPeriod"
            v-model="searchParam.reviewRequestPeriod"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="사전검토 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="mdmChemReviewId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-review-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        reviewRequestPeriod: [],
        mdmChemReviewStepCd: 'MCR0000010',
        chemFlag: true,
      },
      grid: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재일련번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'name',
            field: 'name',
            label: '화학자재명',
            child: [
              {
                name: 'materialName',
                field: 'materialName',
                label: '한글',
                align: 'left',
                type: 'link',
                style: 'width:250px',
                sortable: true,
              },
              {
                name: 'materialNameEn',
                field: 'materialNameEn',
                label: '영문',
                align: 'left',
                style: 'width:200px',
                sortable: true,
              },
            ]
          },
          {
            name: 'mdmChemReviewStepName',
            field: 'mdmChemReviewStepName',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: 'LBLREGISTER',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'review',
            field: 'review',
            label: '검토',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'proxy',
            component: () => import(`${'@/pages/mdm/mam/review/reviewStatusProxy.vue'}`)
          },
          {
            name: 'plantNames',
            field: 'plantNames',
            label: '사업장명',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.review.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '선택이 되지 않았습니다.', // 선택이 되지 않았습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
